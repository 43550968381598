import { RefObject } from 'react'
import { getSectionHeights } from '../utils/useScrollPosition'

const fixedSideAlignment = (
  windowWidth: number,
  sectionWidth: number,
  pctOffset: number = 10,
  isLeftAligned: boolean = true
) => {
  const sectionOffset = windowWidth ? (windowWidth - sectionWidth) / 2 : 0
  const offset = sectionWidth * (pctOffset / 100)
  return isLeftAligned ? offset + sectionOffset : offset - sectionOffset
}

/** Given an absolute positioned element, it obtains it's equivalent fixed position on the viewport */
const getFixedPosition = (refElement: RefObject<HTMLElement>) => {
  if (!refElement?.current) {
    return null
  }

  const sectionRectangle = refElement?.current?.offsetParent?.getBoundingClientRect() || { left: 0, top: 0 }
  return {
    left: sectionRectangle.left + refElement.current.offsetLeft + refElement.current.offsetWidth / 2,
    top: sectionRectangle.top + refElement.current.offsetTop + refElement.current.offsetHeight / 2,
  }
}

/** Given a section index, it will return the offset of the section from the
 * top of the view port.
 */
const sectionViewportOffset = (index: number, scrollRatio: number, windowHeight: number, isMobile: boolean = false) => {
  const scrollOffset = scrollRatio * windowHeight
  const sectionStartsAt = getSectionHeights(windowHeight, isMobile).reduce(
    (acc, height, pos) => (pos < index ? acc + height : acc),
    0
  )

  return scrollOffset - sectionStartsAt
}

export { fixedSideAlignment, getFixedPosition, sectionViewportOffset }
