import React, { useContext, useRef, useEffect, LegacyRef } from 'react'
import GlobalStateContext from '../../state'
import ScrollableSection from '../../components/scrollableSection'
import useResponsive from '../../utils/useResponsive'
import { getSectionHeights, MenuHeights } from '../../utils/useScrollPosition'
import useSectionWidth from '../../utils/useSectionWidth'
import { FixedToAbsolute } from '../../components/positioning'
import ResponsiveImage from '../../components/responsiveImage'
import { sectionViewportOffset } from '../helpers'
import events from '../storyline'
import styles from './AboutUs.module.css'
import { interpolate } from '@popmotion/popcorn'
import { PositionProperty } from 'csstype'

/** Renders the Who we are section. */
const AboutUsSection = () => {
  const { sectionRef, sectionWidth, windowWidth } = useSectionWidth()
  const { windowHeight } = useContext(GlobalStateContext)
  const { isMobile: isMobileSection } = useResponsive()

  return (
    <div
      className={styles.section}
      ref={sectionRef}
      css={{ height: `${getSectionHeights(windowHeight, isMobileSection)[4]}px` }}
    >
      <ScrollableSection index={[3]}>
        {({ scrollRatio, isMobile }) => {
          return (
            <AboutUsInternal
              scrollRatio={scrollRatio}
              sectionWidth={sectionWidth}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              isMobile={isMobile}
            />
          )
        }}
      </ScrollableSection>
    </div>
  )
}

interface IProps {
  scrollRatio: number
  sectionWidth: number
  windowWidth: number
  windowHeight: number
  isMobile: boolean
}

/** Given that the video image has to be switched out for the logo, we need to save
 * the Video/logo height ratio in order to generate a logo of the right size.
 */
const VIDEO_LENGTH_SECS = 7
const VIDEO_FPS = 30
const MS_FRAME = 1000 / VIDEO_FPS
const VIDEO_LOGO_TO_WIDTH_RATIO = 0.235
const finalLogoWidth = (isMobile: boolean) => (isMobile ? 500 : 200)

const scrollToVideoTimeInterpolator = (isMobile: boolean) =>
  isMobile
    ? interpolate(
        [events(isMobile).videoSectionReachedTop, events(isMobile).aboutUsVideoFinished],
        [0, VIDEO_LENGTH_SECS]
      )
    : interpolate(
        [
          events(isMobile).aboutUsSectionReachedTop,
          events(isMobile).aboutUsSectionTitleOpacityStart,
          events(isMobile).aboutUsSectionLearnMoreOpacityEnd,
          events(isMobile).aboutUsSectionWhoWeAreScrolledOutFounders,
          events(isMobile).aboutUsVideoFinished,
        ],
        [0, 0.6, 1.2, 2.84, VIDEO_LENGTH_SECS]
      )

const titleOpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).aboutUsSectionTitleOpacityStart, events(isMobile).aboutUsSectionTitleOpacityEnd],
    isMobile ? [1, 1] : [0, 1]
  )
const imageOpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).aboutUsSectionImageOpacityStart, events(isMobile).aboutUsSectionImageOpacityEnd],
    isMobile ? [1, 1] : [0, 1]
  )
const descriptionOpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).aboutUsSectionDescriptionOpacityStart, events(isMobile).aboutUsSectionDescriptionOpacityEnd],
    isMobile ? [1, 1] : [0, 1]
  )
const learnMoreOpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).aboutUsSectionLearnMoreOpacityStart, events(isMobile).aboutUsSectionLearnMoreOpacityEnd],
    isMobile ? [1, 1] : [0, 1]
  )

const backgroundColorTransparencyInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).aboutUsSectionWhoWeAreTransparencyStart, events(isMobile).aboutUsSectionWhoWeAreTransparencyEnd],
    [1, 0]
  )

const videoLeftInterpolator = (isMobile: boolean, sectionWidth: number) =>
  interpolate(
    [events(isMobile).aboutUsVideoFinished, events(isMobile).end],
    [sectionWidth / 2, isMobile ? sectionWidth / 2 : 0.15 * sectionWidth]
  )

const videoTopInterpolator = (isMobile: boolean, windowHeight: number) =>
  interpolate(
    [events(isMobile).aboutUsVideoFinished, events(isMobile).end],
    [windowHeight / 2, isMobile ? 0.95 * windowHeight : 1.05 * windowHeight]
  )

const videoDesktopWidthInterpolator = (isMobile: boolean, windowWidth: number, desiredWidth: number) =>
  interpolate([events(isMobile).aboutUsVideoFinished, events(isMobile).end], [windowWidth, desiredWidth])

const videoMobileHeightInterpolator = (isMobile: boolean, windowHeight: number, desiredHeight: number) =>
  interpolate([events(isMobile).aboutUsVideoFinished, events(isMobile).end], [windowHeight, desiredHeight])

/** Internal component used to render the about us section. */
const AboutUsInternal = ({ scrollRatio, sectionWidth, windowWidth, windowHeight, isMobile }: IProps) => {
  const {
    pageData: { sections, videos, images },
  } = useContext(GlobalStateContext)
  const videoAnimationRef = useRef(0)
  const videoRef: LegacyRef<HTMLVideoElement> = useRef(null)

  const updateVideoPosition = () => {
    const toTime = scrollToVideoTimeInterpolator(isMobile)(scrollRatio) as number
    // estimate the neares frame.
    const toTimeMs = toTime * 1000
    const nearestFrameNumber = Math.round(toTimeMs / MS_FRAME)
    videoRef.current.currentTime = (nearestFrameNumber * MS_FRAME) / 1000
  }

  useEffect(() => {
    videoAnimationRef.current = window.requestAnimationFrame(updateVideoPosition)
    return () => {
      if (videoAnimationRef.current) {
        window.cancelAnimationFrame(videoAnimationRef.current)
      }
    }
  }, [scrollRatio, videoRef.current, videoAnimationRef.current])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.width = windowWidth
    }
  }, [videoRef.current, windowHeight, windowWidth])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
      videoRef.current.pause()
    }
  }, [videoRef.current])

  const endLogoWidth = finalLogoWidth(isMobile)

  const sectionData = sections[4]
  const bgVideo = videos['qvinVideo']
  const videoWidth = videoDesktopWidthInterpolator(
    isMobile,
    windowWidth,
    endLogoWidth / VIDEO_LOGO_TO_WIDTH_RATIO
  )(scrollRatio)

  const videoHeight = videoMobileHeightInterpolator(isMobile, windowHeight, endLogoWidth / 1.7777)(scrollRatio)

  const videoStyle = {
    position: 'absolute' as PositionProperty,
    top: `${videoTopInterpolator(isMobile, windowHeight)(scrollRatio)}px`,
    left: `${videoLeftInterpolator(isMobile, sectionWidth)(scrollRatio)}px`,
    transform: `translate3d(-50%, -50%, 0)`,
    maxHeight: `${windowHeight}px`,
    ...(isMobile ? { height: `${videoHeight}px` } : { width: `${videoWidth}px` }),
  }

  const renderVideoSection = () => (
    <FixedToAbsolute
      id="aboutUsVideo"
      className={styles.internalWrapper}
      ratio={scrollRatio}
      intervals={
        isMobile
          ? [
              {
                start: events(isMobile).start,
                end: events(isMobile).videoSectionReachedTop,
                position: 'absolute',
                left: 0,
                top: windowHeight + MenuHeights.mobile,
              },
              {
                start: events(isMobile).videoSectionReachedTop,
                end: events(isMobile).aboutUsVideoFinished,
                position: 'fixed',
                left: 0,
                top: MenuHeights.mobile,
              },
              {
                start: events(isMobile).aboutUsVideoFinished,
                position: 'absolute',
                left: 0,
                top:
                  MenuHeights.mobile +
                  sectionViewportOffset(4, events(isMobile).aboutUsVideoFinished, windowHeight, isMobile),
              },
            ]
          : [
              {
                start: events(isMobile).start,
                end: events(isMobile).aboutUsSectionReachedTop,
                position: 'absolute',
                left: 0,
                top: 0,
              },
              {
                start: events(isMobile).aboutUsSectionReachedTop,
                end: events(isMobile).aboutUsVideoFinished,
                position: 'fixed',
                left: 0,
                top: 0,
              },
              {
                start: events(isMobile).aboutUsVideoFinished,
                position: 'absolute',
                left: 0,
                top: sectionViewportOffset(4, events(isMobile).aboutUsVideoFinished, windowHeight, isMobile),
              },
            ]
      }
      sectionWidth={sectionWidth}
    >
      <div
        className={styles.layout}
        css={{
          height: `${windowHeight}px`,
        }}
      >
        <div css={videoStyle}>
          <video
            ref={videoRef}
            className={styles.video}
            loop
            playsInline
            muted
            css={{
              ...(isMobile
                ? {
                    height: `${videoHeight}px`,
                    width: `${windowWidth}px`,
                    objectFit: 'cover',
                  }
                : {}),
            }}
          >
            <source src={bgVideo.url} type={bgVideo.contentType} />
          </video>
        </div>
      </div>
    </FixedToAbsolute>
  )

  const renderWhoWeAreSection = () => (
    <FixedToAbsolute
      id="whoWeAre"
      className={styles.internalWrapper}
      ratio={scrollRatio}
      css={{ zIndex: 60 }}
      intervals={
        isMobile
          ? [
              {
                start: events(isMobile).start,
                position: 'absolute',
                left: 0,
                top: 0,
              },
            ]
          : [
              {
                start: events(isMobile).start,
                end: events(isMobile).aboutUsSectionReachedTop,
                position: 'absolute',
                left: 0,
                top: 0,
              },
              {
                start: events(isMobile).aboutUsSectionReachedTop,
                end: events(isMobile).aboutUsSectionWhoWeAreTransparencyEnd,
                position: 'fixed',
                left: 0,
                top: 0,
              },
              {
                start: events(isMobile).aboutUsSectionWhoWeAreTransparencyEnd,
                position: 'absolute',
                left: 0,
                top: sectionViewportOffset(
                  4,
                  events(isMobile).aboutUsSectionWhoWeAreTransparencyEnd,
                  windowHeight,
                  isMobile
                ),
              },
            ]
      }
      sectionWidth={sectionWidth}
    >
      <div
        className={styles.layout}
        css={{
          height: `${windowHeight}px`,
          paddingTop: `${isMobile ? MenuHeights.mobile : MenuHeights.desktop}px`,
        }}
      >
        <div
          className={styles.whoWeAreContainer}
          css={{
            // @ts-ignore
            backgroundColor: `rgba(255, 255, 255, ${backgroundColorTransparencyInterpolator(isMobile)(scrollRatio)})`,
            height: `${windowHeight}px`,
          }}
        >
          <div className={styles.whoWeArePanel}>
            <div className={styles.sectionTitle} css={{ opacity: titleOpacityInterpolator(isMobile)(scrollRatio) }}>
              {sectionData.title}
            </div>
            <ResponsiveImage
              className={styles.foundersImage}
              variants={images['founders']}
              alt="Step 3 sphere"
              css={{ opacity: imageOpacityInterpolator(isMobile)(scrollRatio) }}
            />
            <div
              className={styles.sectionDescription}
              css={{ opacity: descriptionOpacityInterpolator(isMobile)(scrollRatio) }}
            >
              {sectionData.description}
            </div>
            <a
              href=""
              className={styles.learnMore}
              css={{ opacity: learnMoreOpacityInterpolator(isMobile)(scrollRatio) }}
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </FixedToAbsolute>
  )

  return (
    <>
      {isMobile ? renderWhoWeAreSection() : renderVideoSection()}
      {isMobile ? renderVideoSection() : renderWhoWeAreSection()}
    </>
  )
}

export default AboutUsSection
