import React, { useContext, useRef, useEffect, LegacyRef, CSSProperties } from 'react'
import GlobalStateContext from '../../state'
import ScrollableSection from '../../components/scrollableSection'
import useResponsive from '../../utils/useResponsive'
import { getSectionHeights, MenuHeights } from '../../utils/useScrollPosition'
import useSectionWidth from '../../utils/useSectionWidth'
import { FixedToAbsolute } from '../../components/positioning'
import ResponsiveImage from '../../components/responsiveImage'
import { sectionViewportOffset } from '../helpers'
import events from '../storyline'
import styles from './PowerPeriodSection.module.css'
import { interpolate } from '@popmotion/popcorn'
import { PositionProperty } from 'csstype'

/** Renders the Power to the period section. */
const PowerPeriodSection = () => {
  const { sectionRef, sectionWidth, windowWidth } = useSectionWidth()
  const { windowHeight } = useContext(GlobalStateContext)
  const { isMobile: isMobileSection } = useResponsive()

  return (
    <div
      className={styles.section}
      ref={sectionRef}
      css={{ height: `${getSectionHeights(windowHeight, isMobileSection)[3]}px` }}
    >
      <ScrollableSection index={[3]}>
        {({ scrollRatio, isMobile }) => {
          return (
            <PowerPeriodInternal
              scrollRatio={scrollRatio}
              sectionWidth={sectionWidth}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              isMobile={isMobile}
            />
          )
        }}
      </ScrollableSection>
    </div>
  )
}

interface IProps {
  scrollRatio: number
  sectionWidth: number
  windowWidth: number
  windowHeight: number
  isMobile: boolean
}

/** Given that the video image has to be switched out for the logo, we need to save
 * the Video/logo height ratio in order to generate a logo of the right size.
 */
const VIDEO_LENGTH_SECS = 7.5
const VIDEO_FPS = 30
const MS_FRAME = 1000 / VIDEO_FPS

const scrollToVideoTimeInterpolator = (isMobile: boolean) =>
  interpolate(
    [
      events(isMobile).powerVideoStarted,
      events(isMobile).powerVideoWhiteLineStart,
      events(isMobile).powerVideoFinished,
    ],
    [0, 4, VIDEO_LENGTH_SECS]
  )

const titleOpacityInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).powerTitleOpacityOnStart, events(isMobile).powerTitleOpacityOnEnd], [0, 1])
const descriptionOpacityInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).powerDescriptionOpacityOnStart, events(isMobile).powerDescriptionOpacityOnEnd], [0, 1])

/** Internal component used to render the power to the period section. */
const PowerPeriodInternal = ({ scrollRatio, sectionWidth, windowWidth, windowHeight, isMobile }: IProps) => {
  const {
    pageData: { sections, videos, images },
  } = useContext(GlobalStateContext)
  const videoAnimationRef = useRef(0)
  const videoRef: LegacyRef<HTMLVideoElement> = useRef(null)

  const updateVideoPosition = () => {
    const toTime = scrollToVideoTimeInterpolator(isMobile)(scrollRatio) as number
    // estimate the neares frame.
    const toTimeMs = toTime * 1000
    const nearestFrameNumber = Math.round(toTimeMs / MS_FRAME)
    videoRef.current.currentTime = (nearestFrameNumber * MS_FRAME) / 1000
  }

  useEffect(() => {
    videoAnimationRef.current = window.requestAnimationFrame(updateVideoPosition)
    return () => {
      if (videoAnimationRef.current) {
        window.cancelAnimationFrame(videoAnimationRef.current)
      }
    }
  }, [scrollRatio, videoRef.current, videoAnimationRef.current])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.width = windowWidth
    }
  }, [videoRef.current, windowHeight, windowWidth])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
      videoRef.current.pause()
    }
  }, [videoRef.current])

  const sectionData = sections[3]
  const powerVideo = videos['powerVideo']
  const sectionVideoHeight = windowHeight - (isMobile ? MenuHeights.mobile : MenuHeights.desktop)

  const videoStyle = {
    position: 'absolute' as PositionProperty,
    top: `${windowHeight / 2}px`,
    left: `${sectionWidth / 2}px`,
    transform: `translate3d(-50%, -50%, 0)`,
    height: `${windowHeight}px`,
    width: `${windowWidth}px`,
  }

  return (
    <FixedToAbsolute
      id="powerVideo"
      className={styles.internalWrapper}
      ratio={scrollRatio}
      intervals={[
        {
          start: events(isMobile).start,
          end: events(isMobile).powerSectionReachedTop(windowHeight),
          position: 'absolute' as PositionProperty,
          left: 0,
          top: 0,
        },
        {
          start: events(isMobile).powerSectionReachedTop(windowHeight),
          end: events(isMobile).powerSectionShouldScrollOut,
          position: 'fixed' as PositionProperty,
          left: 0,
          top: isMobile ? MenuHeights.mobile : MenuHeights.desktop,
        },
        {
          start: events(isMobile).powerSectionShouldScrollOut,
          position: 'absolute' as PositionProperty,
          left: 0,
          top:
            sectionViewportOffset(3, events(isMobile).powerSectionShouldScrollOut, windowHeight, isMobile) +
            (isMobile ? MenuHeights.mobile : MenuHeights.desktop),
        },
      ]}
      sectionWidth={sectionWidth}
    >
      <div
        className={styles.layout}
        css={{
          height: `${sectionVideoHeight}px`,
        }}
      >
        <div css={videoStyle}>
          <video
            ref={videoRef}
            className={styles.video}
            loop
            playsInline
            muted
            css={{
              height: `${sectionVideoHeight}px`,
              width: `${windowWidth}px`,
              objectFit: 'cover',
            }}
          >
            <source src={powerVideo.url} type={powerVideo.contentType} />
          </video>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.sectionTitle} css={{ opacity: titleOpacityInterpolator(isMobile)(scrollRatio) }}>
            {sectionData.title}
          </div>
          <div
            className={styles.sectionDescription}
            css={{ opacity: descriptionOpacityInterpolator(isMobile)(scrollRatio) }}
          >
            {sectionData.description}
          </div>
        </div>
      </div>
    </FixedToAbsolute>
  )
}

export default PowerPeriodSection
