import { interpolate } from '@popmotion/popcorn'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import events from '../../sections/storyline'
import GlobalStateContext from '../../state'
import { MenuKeys, Sections } from '../../types/types'
import useResponsive from '../../utils/useResponsive'
import useScrollPosition from '../../utils/useScrollPosition'
import './burger-menu.css'
import styles from './NavigationBar.module.css'

const MENU_ENTER_DELTA = 0.3
const MENU_EXIT_DELTA = 0.4

interface MenuThreshold {
  enterStart?: number
  enterEnd?: number
  exitStart?: number
  exitEnd?: number
  target?: number
  url?: string
  absoluteUrl?: string
}

type MenuLinksMap = {
  [key in MenuKeys]: MenuThreshold
}

const menuLinksToStoryline = (isMobile: boolean, windowHeight: number): MenuLinksMap => ({
  [Sections.mission]: {
    enterStart: events(isMobile).start,
    enterEnd: events(isMobile).missionTitleFadeOutStart,
    exitStart: events(isMobile).qPadSectionReachedTop(windowHeight) - MENU_EXIT_DELTA,
    exitEnd: events(isMobile).qPadSectionReachedTop(windowHeight) - MENU_ENTER_DELTA,
    target: events(isMobile).missionTitleFadeOutStart,
  },
  [Sections.qpad]: {
    enterStart: events(isMobile).qPadSectionReachedTop(windowHeight) - MENU_ENTER_DELTA,
    enterEnd: events(isMobile).qPadSectionReachedTop(windowHeight),
    exitStart: events(isMobile).howItWorksSectionReachedTop - MENU_EXIT_DELTA,
    exitEnd: events(isMobile).howItWorksSectionReachedTop - MENU_ENTER_DELTA,
    target: events(isMobile).qPadDescriptionFadeInEnd,
  },
  [Sections.howTo]: {
    enterStart: events(isMobile).howItWorksSectionReachedTop - MENU_ENTER_DELTA,
    enterEnd: events(isMobile).howItWorksSectionReachedTop,
    exitStart: events(isMobile).powerSectionReachedTop(windowHeight) - MENU_EXIT_DELTA,
    exitEnd: events(isMobile).powerSectionReachedTop(windowHeight) - MENU_ENTER_DELTA,
    target: isMobile ? events(isMobile).howItWorksSectionReachedTop : events(isMobile).howItWorksStep3OpacityEnd,
  },
  [Sections.power]: {
    enterStart: events(isMobile).powerSectionReachedTop(windowHeight) - MENU_ENTER_DELTA,
    enterEnd: events(isMobile).powerSectionReachedTop(windowHeight),
    exitStart: events(isMobile).aboutUsSectionReachedTop - MENU_EXIT_DELTA,
    exitEnd: events(isMobile).aboutUsSectionReachedTop - MENU_ENTER_DELTA,
    target: events(isMobile).powerDescriptionOpacityOnEnd,
  },
  [Sections.aboutUs]: {
    enterStart: events(isMobile).aboutUsSectionReachedTop - MENU_ENTER_DELTA,
    enterEnd: events(isMobile).aboutUsSectionImageOpacityEnd,
    exitStart: events(isMobile).aboutUsSectionVideoScaleStart,
    exitEnd: events(isMobile).aboutUsSectionVideoScaleStart + MENU_ENTER_DELTA,
    target: isMobile ? events(isMobile).aboutUsSectionReachedTop : events(isMobile).aboutUsSectionLearnMoreOpacityEnd,
  },
  [Sections.contact]: {
    enterStart: events(isMobile).aboutUsSectionVideoScaleStart + MENU_ENTER_DELTA,
    enterEnd: events(isMobile).aboutUsSectionVideoScaleStart + 2 * MENU_ENTER_DELTA,
    exitStart: 100,
    exitEnd: 100,
    target: events(isMobile).end + 1,
  },
  [Sections.careers]: {
    absoluteUrl: 'https://careers.qvin.com/',
  },
})

const menuUnderlineInterpolator = (threshold: MenuThreshold) =>
  interpolate([threshold.enterStart, threshold.enterEnd, threshold.exitStart, threshold.exitEnd], [0, 100, 100, 0])

const NavigationBar = () => {
  const {
    pageData: { menus, logo },
    windowHeight,
    locale,
  } = useContext(GlobalStateContext)
  const scrollRatio = useScrollPosition(windowHeight)
  const [menuOpen, setMenuIsOpen] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const { isMobile } = useResponsive()

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const handleMenuOpen = (state: boolean) => () => {
    setMenuIsOpen(state)
  }

  const getLinkStyle = (menuKey: MenuKeys) => {
    const menuThresholds = menuLinksToStoryline(isMobile, windowHeight)[menuKey]

    return { width: `${menuUnderlineInterpolator(menuThresholds)(scrollRatio)}%` }
  }

  const renderMobile = () => (
    <div className={styles.drawerContainer}>
      <Menu right disableAutoFocus isOpen={menuOpen} onStateChange={({ isOpen }) => handleMenuOpen(isOpen)()}>
        {Object.keys(menus).map((menuKey: MenuKeys) => (
          <div key={menuKey}>{shouldRenderLink(menuKey) && renderMobileLink(menuKey)}</div>
        ))}
      </Menu>
    </div>
  )

  const shouldRenderLink = (menuKey: MenuKeys) => {
    // @ts-ignore
    return true
  }

  const renderMobileLink = (menuKey: MenuKeys) => {
    const menuLinksMapElement = menuLinksToStoryline(isMobile, windowHeight)[menuKey]
    if (menuLinksMapElement?.absoluteUrl != null) {
      return (
        <a key={menuKey} className={styles.mobileLink} href={menuLinksMapElement.absoluteUrl}>
          {menus[menuKey]}
        </a>
      )
    } else {
      return (
        <a key={menuKey} className={styles.mobileLink} onClick={handleLinkClicked(menuKey)}>
          {menus[menuKey]}
        </a>
      )
    }
  }

  const renderDesktop = () => {
    return Object.keys(menus).map((menuKey: MenuKeys) => (
      <div key={menuKey}>{shouldRenderLink(menuKey) && renderDesktopLink(menuKey)}</div>
    ))
  }

  const renderDesktopLink = (menuKey: MenuKeys) => {
    const menuLinksMapElement = menuLinksToStoryline(isMobile, windowHeight)[menuKey]
    if (menuLinksMapElement?.absoluteUrl != null) {
      return (
        <a key={menuKey} className={styles.desktopLink} href={menuLinksMapElement.absoluteUrl}>
          {menus[menuKey]}
        </a>
      )
    } else {
      return (
        <a key={menuKey} className={styles.desktopLink} onClick={handleLinkClicked(menuKey)}>
          {menus[menuKey]}
          {menuKey !== Sections.careers && (
            <div className={styles.linkUnderline} css={getLinkStyle(menuKey)} />
          )}
        </a>
      )
    }
  }

  const handleLinkClicked = (menuItem: MenuKeys) => async () => {
    const menuLinksMapElement = menuLinksToStoryline(isMobile, windowHeight)[menuItem]
    if (menuLinksMapElement?.url != null) {
      navigate(`${menuLinksMapElement?.url}`)
    } else {
      const linkTarget = menuLinksMapElement?.target || 0
      setMenuIsOpen(false)

      if (typeof window !== 'undefined') {
        window.scrollTo(0, linkTarget * windowHeight)
      }
    }
  }

  return (
    <div className={styles.container}>
      <nav className={styles.navBar}>
        <Link to="/">
          <img src={logo.url} alt="Logo" className={styles.logo} />
        </Link>
        <div className={styles.desktopLinksContainer} css={{ opacity: isMounted ? 1 : 0 }}>
          {isMobile ? renderMobile() : renderDesktop()}
        </div>
      </nav>
    </div>
  )
}

export default NavigationBar
