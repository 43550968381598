import React, { RefObject } from 'react'
import { ImageVariant } from '../../types/types'
import CSS from 'csstype'

interface IProps {
  variants: Array<ImageVariant>
  alt: string
  className?: string
  style?: CSS.Properties
  imageRef?: RefObject<HTMLImageElement>
}

/** Renders a responsive image capable of using an image src array to
 * generate a srcset.
 */
const ResponsiveImage = ({ variants, imageRef, ...other }: IProps) => {
  const smallestImage = variants.reduce((acc, v) => {
    return v.width < acc.width ? v : acc
  }, variants[0])
  const srcSet = variants.reduce((acc, v) => {
    return `${acc}${acc ? ', ' : ''}${v.url} ${v.width}w`
  }, '')

  return <img src={smallestImage.url} srcSet={srcSet} ref={imageRef} {...other} />
}

export default ResponsiveImage
