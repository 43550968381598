import React, { useState, RefObject } from 'react'
import Layout from '../layout/Layout'
import GlobalStateContext from '../../state/LandingPageContext'
import { MainPageData, SupportedLanguages, PositionAndSize } from '../../types/types'
import NavigationBar from '../navigation'
import {
  AboutUsSection,
  MissionSection,
  QPadSection,
  GlobalSphere,
  HowItWorks,
  StayInTouch,
  PowerPeriod,
} from '../../sections'

type IProps = {
  locale: SupportedLanguages
  data: MainPageData
}

const DEFAULT_WINDOW_HEIGHT = 1920

const LocalizedIndex = ({ locale, data }: IProps) => {
  const [anchors, setAnchors] = useState({})
  const [windowHeight, setWindowHeight] = useState(DEFAULT_WINDOW_HEIGHT)

  const updateAnchor = (anchorName: string, position: PositionAndSize) => {
    setAnchors(currentAnchors => ({ ...currentAnchors, [anchorName]: position }))
  }

  const handleLayoutMounted = (mountedHeight: number) => {
    setWindowHeight(mountedHeight)
  }

  const renderLayout = () => (
    <Layout onLayoutMounted={handleLayoutMounted} windowHeight={windowHeight}>
      <GlobalSphere />
      <NavigationBar />
      <MissionSection />
      <QPadSection />
      <HowItWorks />
      <PowerPeriod />
      <AboutUsSection />
      <StayInTouch />
    </Layout>
  )

  return (
    <>
      <GlobalStateContext.Provider
        value={{ locale, pageData: data, anchors, updateAnchor, isMobile: false, windowHeight }}
      >
        {renderLayout()}
      </GlobalStateContext.Provider>
      <GlobalStateContext.Provider
        value={{ locale, pageData: data, anchors, updateAnchor, isMobile: true, windowHeight }}
      >
        {renderLayout()}
      </GlobalStateContext.Provider>
    </>
  )
}

export default LocalizedIndex
