import React, { useContext, useRef, useEffect } from 'react'
import { interpolate } from '@popmotion/popcorn'
import { css } from '@emotion/core'
import { FixedToAbsolute } from '../../components/positioning'
import GlobalStateContext from '../../state'
import ResponsiveImage from '../../components/responsiveImage'
import ScrollableSection from '../../components/scrollableSection'
import useSectionWidth from '../../utils/useSectionWidth'
import useResponsive from '../../utils/useResponsive'
import { getSectionHeights, MenuHeights } from '../../utils/useScrollPosition'
import { getFixedPosition, sectionViewportOffset } from '../helpers'
import Breakpoints from '../../utils/breakpoints'
import events from '../storyline'
import styles from './QPadSection.module.css'

const headerOpacityInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).qPadTitleFadeInStart, events(isMobile).qPadTitleFadeInEnd], [0, 1])
const descriptionOpacityInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).qPadDescriptionFadeInStart, events(isMobile).qPadDescriptionFadeInEnd], [0, 1])

const QPadSection = () => {
  const { sectionRef, sectionWidth, windowWidth } = useSectionWidth()
  const { windowHeight } = useContext(GlobalStateContext)
  const { isMobile: isMobileSection } = useResponsive()

  return (
    <div
      className={styles.section}
      ref={sectionRef}
      css={{ height: `${getSectionHeights(windowHeight, isMobileSection)[1]}px` }}
    >
      <ScrollableSection index={[1]}>
        {({ scrollRatio, sectionHeight, isMobile }) => (
          <QPadSectionInternal
            scrollRatio={scrollRatio}
            sectionHeight={sectionHeight}
            windowWidth={windowWidth}
            sectionWidth={sectionWidth}
            isMobile={isMobile}
          />
        )}
      </ScrollableSection>
    </div>
  )
}

interface IProps {
  scrollRatio: number
  sectionHeight: number
  sectionWidth: number
  windowWidth: number
  isMobile: boolean
}

const QPadSectionInternal = ({ scrollRatio, sectionHeight, sectionWidth, isMobile }: IProps) => {
  const qPadRef = useRef(null)
  const {
    pageData: { sections, images },
    updateAnchor,
    windowHeight,
  } = useContext(GlobalStateContext)

  const sectionData = sections[1]
  useEffect(() => {
    const qPadPosition = getFixedPosition(qPadRef)
    updateAnchor('qPad', {
      top: qPadPosition.top - qPadRef.current.offsetHeight / 2,
      left: qPadPosition.left - (isMobile ? qPadRef.current.offsetWidth / 2 : 0),
    })
  }, [qPadRef, scrollRatio])

  return (
    <FixedToAbsolute
      id="qPadSection"
      className={styles.internalWrapper}
      ratio={scrollRatio}
      intervals={[
        {
          start: events(isMobile).start,
          end: events(isMobile).qPadSectionReachedTop(windowHeight),
          position: 'absolute',
          top: 0,
          left: 0,
        },
        {
          start: events(isMobile).qPadSectionReachedTop(windowHeight),
          end: events(isMobile).qPadSectionShouldScrollOut,
          position: 'fixed',
          top: isMobile ? MenuHeights.mobile : MenuHeights.desktop,
          left: 0,
        },
        {
          start: events(isMobile).qPadSectionShouldScrollOut,
          position: 'absolute',
          top:
            sectionViewportOffset(1, events(isMobile).qPadSectionShouldScrollOut, windowHeight, isMobile) +
            (isMobile ? MenuHeights.mobile : MenuHeights.desktop),
          left: 0,
        },
      ]}
      sectionWidth={sectionWidth}
    >
      <div
        className={styles.layout}
        css={{
          height: `${windowHeight - MenuHeights.desktop}px`,
          [`@media (max-width: ${Breakpoints.MAX_MOBILE}px)`]: {
            height: `${windowHeight - MenuHeights.mobile}px`,
          },
        }}
      >
        <div
          className={styles.headerText}
          css={{
            opacity: headerOpacityInterpolator(isMobile)(scrollRatio),
            top: `${sectionHeight * 0.1}px`,
            right: `10%`,
            [`@media (max-width: ${Breakpoints.MAX_MOBILE}px)`]: {
              top: `${sectionHeight * 0.05}px`,
              right: 'unset',
              left: '10%',
            },
          }}
        >
          {sectionData.title}
        </div>
        <div
          className={styles.descriptionText}
          css={{
            opacity: descriptionOpacityInterpolator(isMobile)(scrollRatio),
            top: `${sectionHeight * 0.45}px`,
            right: `10%`,
            [`@media (max-width: ${Breakpoints.MAX_MOBILE}px)`]: {
              top: 'unset',
              bottom: `${24}px`,
              right: 'unset',
              left: '10%',
            },
          }}
        >
          {sectionData.description}
        </div>
        <ResponsiveImage
          className={styles.padImage}
          variants={images['QPad']}
          alt="QPad"
          css={{
            top: '50%',
            transform: 'translateY(-50%)',
            left: `10%`,
            width: `${0.4 * sectionWidth}px`,
            [`@media (max-width: ${Breakpoints.MAX_MOBILE}px)`]: {
              left: '50%',
              transform: 'translate3D(-50%, -50%, 0)',
            },
          }}
          imageRef={qPadRef}
        />
        {isMobile && qPadRef.current && (
          <ResponsiveImage
            className={styles.sphere}
            variants={images['sphere3']}
            alt="Sphere"
            css={{
              opacity: headerOpacityInterpolator(isMobile)(scrollRatio),
              top: `${qPadRef.current.offsetTop}px`,
              left: `${qPadRef.current.offsetLeft}px`,
            }}
          />
        )}
        <ResponsiveImage className={styles.background} variants={images['Gradient']} alt="Background gradient" />
      </div>
    </FixedToAbsolute>
  )
}

export default QPadSection
