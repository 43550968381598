import React, { useEffect, useRef, useState, RefObject } from 'react'

/** Hook used to keep track of a section's width. */
const useSectionWidth = () => {
  const sectionRef: RefObject<HTMLDivElement> = useRef()
  const [sectionWidth, setSectionWidth] = useState(0)
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0)
  useEffect(() => {
    const updateSectionWidth = () => {
      if (sectionRef.current.offsetWidth !== sectionWidth) {
        setSectionWidth(sectionRef.current.offsetWidth)
      }
    }
    updateSectionWidth()

    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth)
      window.addEventListener('resize', updateSectionWidth)
    }

    return () => window.removeEventListener('resize', updateSectionWidth)
  }, [sectionRef.current, sectionWidth])

  const sectionOffset = windowWidth > sectionWidth ? (windowWidth - sectionWidth) / 2 : 0
  return { sectionRef, sectionWidth, sectionOffset, windowWidth }
}

export default useSectionWidth
