import { useContext } from 'react'
import useResponsive from '../../utils/useResponsive'
import GlobalStateContext from '../../state'
import useScrollPosition, { getSectionHeights, getAccumulatedHeight } from '../../utils/useScrollPosition'

interface ISectionData {
  scrollRatio: number
  sectionHeight: number
  isMobile: boolean
}

interface IProps {
  index: Array<number>
  children: (data: ISectionData) => JSX.Element
}

/** Scrollable section that provides a responsive section where
 * the scroll ratio for the section is reported to child components.
 */
const ScrollableSection = ({ index, children }: IProps) => {
  const { windowHeight } = useContext(GlobalStateContext)
  const scrollRatio = useScrollPosition(windowHeight)
  const { isMobile } = useResponsive()
  return children({
    scrollRatio,
    sectionHeight: getAccumulatedHeight(getSectionHeights(windowHeight, isMobile), index),
    isMobile,
  })
}

export default ScrollableSection
