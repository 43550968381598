import React, { ReactNode } from 'react'
import useResponsive from '../../utils/useResponsive'
import CSS from 'csstype'

interface Interval {
  start: number
  end?: number | null
  top: number
  position: CSS.PositionProperty
  /** Left position in px. */
  left?: number
  /** Right position in px. */
  right?: number
}

interface IProps {
  children: ReactNode
  /** Scroll ratio of the section that the item belongs to.
   * This is a value between 0 and 1
   */
  ratio: number
  /** Classname */
  className?: string
  /** Parent element width */
  sectionWidth: number
  /** External styles. */
  style?: CSS.Properties
  /** Intervals during which fixed positioning should be used. */
  intervals: Array<Interval>
  /** Element unique key. */
  id: string
}

const currentInterval = (intervals: Array<Interval>, ratio: number) => {
  return (
    intervals.find(
      interval => interval.start <= ratio && (typeof interval.end === 'undefined' || ratio < interval.end)
    ) || intervals[0]
  )
}

/** Wrapper component used to position an element on the screen using fixed
 * positioning, until a certain scroll ratio is reached for it, after which
 * it uses absolute positioning and scrolls out of the view.
 */
const FixedToAbsolute = ({ children, ratio, sectionWidth, intervals, style, id, ...other }: IProps) => {
  const { isMobile } = useResponsive()
  const interval = currentInterval(intervals, ratio)
  const isAbsolute = interval.position === 'absolute'
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const sectionOffset = windowWidth > sectionWidth ? (windowWidth - sectionWidth) / 2 : 0
  const positionOffset = isAbsolute ? sectionOffset : 0

  const componentStyles = {
    ...style,
    position: interval.position,
    top: `${interval.top}px`,
    ...(interval.position === 'fixed' ? { zIndex: 50 } : {}),
    ...(interval.left ? { left: `${interval.left - positionOffset}px` } : {}),
    ...(interval.right ? { right: `${interval.right - positionOffset}px` } : {}),
  }

  return (
    <div {...other} style={componentStyles} key={`${id}${isMobile}`}>
      {children}
    </div>
  )
}

export default FixedToAbsolute
