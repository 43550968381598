import {
  ContentfulImage,
  ContentfulVideo,
  ImagesMap,
  MainPageData,
  MainPageRawData,
  PaginatedList,
  VideosMap,
} from '../types/types'

const extractImages = (images: PaginatedList<ContentfulImage>): ImagesMap => {
  return (images?.nodes || []).reduce((acc: ImagesMap, { title, file: { url, details: { image: { width } } } }) => {
    const imageKey = title.split('@')[0]

    if (acc[imageKey]) {
      acc[imageKey].push({ url, width })
      return acc
    }

    return { ...acc, [imageKey]: [{ url, width }] }
  }, {})
}

const extractVideos = (videos: PaginatedList<ContentfulVideo>): VideosMap => {
  return (videos?.nodes || []).reduce((acc: VideosMap, { title, file }) => {
    return { ...acc, [title]: file }
  }, {})
}

/** Transforms the landing page data from raw state to the MainPageData type */
const transformPageData = (rawData: MainPageRawData): MainPageData => {
  return {
    menus: rawData.allContentfulMenus.nodes[0],
    logo: rawData.logo.nodes[0].file,
    sections: rawData.sections.nodes
      .filter(s => !!s.title)
      .sort((n1, n2) => n1.position - n2.position)
      .map(({ title, description, data }) => ({ title, description: description?.description || '', data })),
    stayInTouchSection: rawData.stayInTouchSection.nodes.find(n => n.signUpTitle !== null),
    images: {
      ...extractImages(rawData.spheres),
      ...extractImages(rawData.background),
      ...extractImages(rawData.qpad),
      ...extractImages(rawData.appStore),
      ...extractImages(rawData.arrow),
      ...extractImages(rawData.googlePlay),
      ...extractImages(rawData.stepImages),
      ...extractImages(rawData.founders),
    },
    videos: {
      ...extractVideos(rawData.logoVideo),
      ...extractVideos(rawData.powerVideo),
    },
  }
}

export { transformPageData, extractImages }
