import React, { useContext } from 'react'
import ScrollableSection from '../../components/scrollableSection'
import { FixedToAbsolute } from '../../components/positioning'
import useSectionWidth from '../../utils/useSectionWidth'
import useResponsive from '../../utils/useResponsive'
import { getSectionHeights, MenuHeights } from '../../utils/useScrollPosition'
import GlobalStateContext from '../../state'
import { interpolate } from '@popmotion/popcorn'
import ResponsiveImage from '../../components/responsiveImage'
import { fixedSideAlignment, sectionViewportOffset } from '../helpers'
import Breakpoints from '../../utils/breakpoints'
import events from '../storyline'
import styles from './MissionSection.module.css'

const headerOpacityInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).missionTitleFadeOutStart, events(isMobile).missionTitleFadeOutEnd], [1, 0])
const descriptionOpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [
      events(isMobile).missionDescriptionFadeInStart,
      events(isMobile).missionDescriptionFadeInEnd,
      events(isMobile).missionDescriptionFadeOutEnd,
    ],
    [0, 1, 0]
  )

/** Component used to render the mission section. */
const MissionSection = () => {
  const {
    pageData: { sections, images },
    windowHeight,
  } = useContext(GlobalStateContext)
  const { isMobile } = useResponsive()
  const { sectionRef, sectionWidth, windowWidth } = useSectionWidth()
  const sectionData = sections[0]
  const optimalSphereHeight = 0.7 * windowHeight
  return (
    <div
      className={styles.section}
      ref={sectionRef}
      key={`MissionSection${isMobile}`}
      css={{ height: `${getSectionHeights(windowHeight, isMobile)[0]}px` }}
    >
      <ScrollableSection index={[0]}>
        {({ scrollRatio, sectionHeight }) => {
          return (
            <>
              <div
                className={styles.headerText}
                css={{
                  opacity: headerOpacityInterpolator(isMobile)(scrollRatio),
                  top: `${sectionHeight * 0.1}px`,
                  left: `${fixedSideAlignment(windowWidth, sectionWidth)}px`,
                }}
              >
                {sectionData.title}
              </div>
              <ResponsiveImage
                className={styles.headerSphere}
                variants={images['sphere0']}
                alt="Sphere"
                css={{
                  position: 'absolute',
                  top: `${64 + (isMobile ? MenuHeights.mobile : MenuHeights.desktop)}px`,
                  right: '10%',
                  maxHeight: `${optimalSphereHeight > 600 ? 600 : optimalSphereHeight}px`,
                  [`@media (max-width: ${Breakpoints.MAX_MOBILE}px)`]: {
                    width: `${0.45 * sectionWidth}px`,
                  },
                }}
              />
              <ResponsiveImage
                className={styles.secondSphere}
                variants={images['sphere1']}
                alt="Sphere"
                css={{
                  top: `${windowHeight * (isMobile ? 0.6 : 1)}px`,
                  maxHeight: `${optimalSphereHeight > 600 ? 600 : optimalSphereHeight}px`,
                  [`@media (max-width: ${Breakpoints.MAX_MOBILE}px)`]: {
                    width: `${0.45 * sectionWidth}px`,
                  },
                }}
              />
              <div
                className={styles.descriptionText}
                css={{
                  opacity: descriptionOpacityInterpolator(isMobile)(scrollRatio),
                  top: `${sectionHeight * 0.11}px`,
                  left: `${fixedSideAlignment(windowWidth, sectionWidth)}px`,
                }}
              >
                {sectionData.description}
              </div>
            </>
          )
        }}
      </ScrollableSection>
    </div>
  )
}

export default MissionSection
