import React, { useContext, useRef, useState, useEffect } from 'react'
import { FixedToAbsolute } from '../../components/positioning'
import GlobalStateContext from '../../state'
import useSectionWidth from '../../utils/useSectionWidth'
import Breakpoints from '../../utils/breakpoints'
import useScrollPosition, { getSectionHeights } from '../../utils/useScrollPosition'
import useResponsive from '../../utils/useResponsive'
import { interpolate } from '@popmotion/popcorn'
import ResponsiveImage from '../../components/responsiveImage'
import { fixedSideAlignment, sectionViewportOffset } from '../helpers'
import events from '../storyline'
import styles from './GlobalSphere.module.css'

const sphereScaleInterpolator = (isMobile: boolean, finalRatio: number) =>
  interpolate(
    [
      events(isMobile).sphereMissionToQPadMoveStarts,
      events(isMobile).sphereMissionToQPadMoveEnds,
      events(isMobile).sphereQPadToHowToMoveStarts,
      events(isMobile).sphereQPadToHowToMoveEnds,
    ],
    [1, 0.2, 0.2, finalRatio || 1]
  )

const qpadCenterOffsetInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).sphereMissionToQPadMoveStarts, events(isMobile).sphereMissionToQPadMoveEnds], [0, 1])

const qPadOverHowToInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).sphereQPadToHowToMoveStarts, events(isMobile).sphereQPadToHowToMoveEnds], [0, 1])

const howToHorizontalTransitionInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).sphereQPadToHowToHorizontalStarts, events(isMobile).sphereQPadToHowToHorizontalEnds],
    [0, 1]
  )

const sphereOpacityInterpolator = (isMobile: boolean, isPrimary: boolean) =>
  interpolate(
    [
      events(isMobile).start,
      events(isMobile).sphereLeavesHowToStep2,
      events(isMobile).sphereEndsHowToStep3,
      events(isMobile).howItWorksGlobalSphereOpacityOffStart,
      events(isMobile).howItWorksGlobalSphereOpacityOffEnd,
    ],
    isPrimary ? [1, 1, 0, 0, 0] : [0, 0, 1, 1, 0]
  )

/** Component used to render the mission section. */
const GlobalSphere = () => {
  const [qPadCentorTop, setQPadCenterTop] = useState(0)
  const { sectionRef, sectionWidth, windowWidth } = useSectionWidth()
  const sphereRef = useRef(null)
  const { isMobile } = useResponsive()
  const {
    anchors,
    windowHeight,
    pageData: { images },
  } = useContext(GlobalStateContext)
  const optimalSphereHeight = 0.7 * windowHeight
  const sphereHeight = isMobile ? 0.45 * sectionWidth : optimalSphereHeight > 600 ? 600 : optimalSphereHeight
  const qPadPosition = anchors.qPad
  const howToAnchor = anchors.howToSphere
  const scrollRatio = useScrollPosition(windowHeight)
  const initialSphereLeft = fixedSideAlignment(windowWidth, sectionWidth, 90) - sphereHeight
  const howToAnchorHeight = howToAnchor?.height || 0
  const sphereScale = isMobile
    ? 1
    : (sphereScaleInterpolator(isMobile, howToAnchorHeight / sphereHeight || 1)(scrollRatio) as number)
  const sphereFixedMissionToQPad =
    getSectionHeights(windowHeight, isMobile)[0] * 0.7 -
    sectionViewportOffset(0, events(isMobile).sphereMissionToQPadMoveStarts, windowHeight, isMobile)
  const scaleOffset = (sphereHeight * (1 - sphereScale)) / 2
  const distanceToQpadX = initialSphereLeft - (qPadPosition?.left || 0) + scaleOffset + (sphereScale * sphereHeight) / 2
  const distanceToQpadY =
    (qPadPosition?.top || 0) - sphereFixedMissionToQPad - scaleOffset - (sphereScale * sphereHeight) / 2

  const distanceToHowToSphereY = (howToAnchor?.top || 0) - qPadCentorTop
  const distanceToHowToSphereX = (howToAnchor?.left || 0) - (qPadPosition?.left || 0)
  const howToHorizontalLeftPosition =
    initialSphereLeft -
    distanceToQpadX +
    (qPadOverHowToInterpolator(isMobile)(scrollRatio) as number) * distanceToHowToSphereX
  const distanceToThirdSphereLeft = windowWidth - 2 * (howToAnchor?.left || 0) - sphereHeight * sphereScale

  useEffect(() => {
    if (
      scrollRatio >= events(isMobile).sphereMissionToQPadMoveStarts &&
      scrollRatio < events(isMobile).sphereQPadToHowToMoveStarts &&
      qPadCentorTop !== anchors.qPad.top
    ) {
      setQPadCenterTop(anchors.qPad.top)
    }
  }, [anchors.qPad?.top, scrollRatio, qPadCentorTop])

  const sphereStyles = {
    maxHeight: `${sphereHeight}px`,
    transform: `scale(${sphereScale})`,
  }

  const cssInline = {
    [`@media (max-width: ${Breakpoints.MAX_MOBILE}px)`]: {
      width: `${0.45 * sectionWidth}px`,
    },
  }

  return (
    <div className={styles.section} ref={sectionRef}>
      {scrollRatio < events(isMobile).howItWorksGlobalSphereOpacityOffEnd && (
        <FixedToAbsolute
          id="globalSphere"
          ratio={scrollRatio}
          className={styles.sphereWrapper}
          intervals={
            isMobile
              ? [
                  {
                    start: events(isMobile).start,
                    position: 'absolute',
                    top: getSectionHeights(windowHeight, isMobile)[0] * 0.7,
                    left: initialSphereLeft,
                  },
                ]
              : [
                  {
                    start: events(isMobile).start,
                    end: events(isMobile).sphereMissionToQPadMoveStarts,
                    position: 'absolute',
                    top: getSectionHeights(windowHeight, isMobile)[0] * 0.7,
                    left: initialSphereLeft,
                  },
                  {
                    start: events(isMobile).sphereMissionToQPadMoveStarts,
                    end: events(isMobile).sphereQPadToHowToMoveStarts,
                    position: 'fixed',
                    top:
                      sphereFixedMissionToQPad +
                      (qpadCenterOffsetInterpolator(isMobile)(scrollRatio) as number) * distanceToQpadY,
                    left:
                      initialSphereLeft -
                      (qpadCenterOffsetInterpolator(isMobile)(scrollRatio) as number) * distanceToQpadX,
                  },
                  {
                    start: events(isMobile).sphereQPadToHowToMoveStarts,
                    end: events(isMobile).sphereQPadToHowToMoveEnds,
                    position: 'fixed',
                    top:
                      qPadCentorTop -
                      scaleOffset +
                      (qPadOverHowToInterpolator(isMobile)(scrollRatio) as number) * distanceToHowToSphereY,
                    left:
                      howToHorizontalLeftPosition +
                      ((qPadOverHowToInterpolator(isMobile)(scrollRatio) as number) * (sphereScale * sphereHeight)) / 2,
                  },
                  {
                    start: events(isMobile).sphereQPadToHowToMoveEnds,
                    position: 'fixed',
                    top:
                      qPadCentorTop -
                      scaleOffset +
                      (qPadOverHowToInterpolator(isMobile)(scrollRatio) as number) * distanceToHowToSphereY,
                    left:
                      howToHorizontalLeftPosition +
                      (sphereScale * sphereHeight) / 2 +
                      (howToHorizontalTransitionInterpolator(isMobile)(scrollRatio) as number) *
                        distanceToThirdSphereLeft,
                  },
                ]
          }
          sectionWidth={sectionWidth}
        >
          <div className={styles.sphereImagesContainer}>
            {sphereOpacityInterpolator(isMobile, true)(scrollRatio) !== 0 && (
              <ResponsiveImage
                className={styles.sphere}
                variants={images['sphere3']}
                alt="Primary sphere"
                style={{ ...sphereStyles, opacity: sphereOpacityInterpolator(isMobile, true)(scrollRatio) }}
                css={cssInline}
                imageRef={sphereRef}
              />
            )}
            {sphereOpacityInterpolator(isMobile, false)(scrollRatio) !== 0 && (
              <ResponsiveImage
                className={styles.sphere}
                variants={images['sphere6']}
                alt="Secondary sphere"
                style={{ ...sphereStyles, opacity: sphereOpacityInterpolator(isMobile, false)(scrollRatio) }}
                css={cssInline}
                imageRef={sphereRef}
              />
            )}
          </div>
        </FixedToAbsolute>
      )}
    </div>
  )
}

export default GlobalSphere
