import React, { useContext } from 'react'
import GlobalStateContext from '../../state'
import ScrollableSection from '../../components/scrollableSection'
import useSectionWidth from '../../utils/useSectionWidth'
import useResponsive from '../../utils/useResponsive'
import { getSectionHeights } from '../../utils/useScrollPosition'
import HowItWorksDesktop from './HowItWorksDesktop'
import HowItWorksMobile from './HowItWorksMobile'
import styles from './HowItWorks.module.css'

const HowItWorks = () => {
  const { sectionRef, sectionWidth, windowWidth } = useSectionWidth()
  const { windowHeight } = useContext(GlobalStateContext)
  const { isMobile: isMobileSection } = useResponsive()

  return (
    <div
      className={styles.section}
      ref={sectionRef}
      css={{ height: `${getSectionHeights(windowHeight, isMobileSection)[2]}px` }}
    >
      <ScrollableSection index={[2]}>
        {({ scrollRatio, isMobile }) =>
          isMobile ? (
            <HowItWorksMobile scrollRatio={scrollRatio} sectionWidth={sectionWidth} />
          ) : (
            <HowItWorksDesktop scrollRatio={scrollRatio} windowWidth={windowWidth} sectionWidth={sectionWidth} />
          )
        }
      </ScrollableSection>
    </div>
  )
}

export default HowItWorks
