import React, { useContext, useRef, useEffect, RefObject } from 'react'
import GlobalStateContext from '../../state'
import { FixedToAbsolute } from '../../components/positioning'
import ResponsiveImage from '../../components/responsiveImage'
import { getSectionHeights, MenuHeights, SectionHeights } from '../../utils/useScrollPosition'
import { fixedSideAlignment, sectionViewportOffset } from '../helpers'
import events from '../storyline'
import styles from './HowItWorksDesktop.module.css'
import { interpolate } from '@popmotion/popcorn'

const GRID_ROW_GAP = 32
const GRID_TITLE_HEIGHT = 72

const sphere1Level1OpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).howItWorksSphere1Level1OpacityOffStart, events(isMobile).howItWorksSphere1Level1OpacityOffEnd],
    [1, 0]
  )
const sphere1Level2OpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).howItWorksSphere1Level1OpacityOffStart, events(isMobile).howItWorksSphere1Level1OpacityOffEnd],
    [0, 1]
  )
const sphere2Level1OpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [
      events(isMobile).howItWorksSphere2Level1OpacityStart,
      events(isMobile).howItWorksSphere2Level1OpacityEnd,
      events(isMobile).howItWorksSphere2Level1OpacityOffStart,
      events(isMobile).howItWorksSphere2Level1OpacityOffEnd,
    ],
    [0, 1, 1, 0]
  )

const sphere2Level2OpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).howItWorksSphere2Level1OpacityOffStart, events(isMobile).howItWorksSphere2Level1OpacityOffEnd],
    [0, 1]
  )

const sphere3Level2OpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [events(isMobile).howItWorksGlobalSphereOpacityOffStart, events(isMobile).howItWorksGlobalSphereOpacityOffEnd],
    [0, 1]
  )

const sectionTextOpacityInterpolator = (section: number, isMobile: boolean) => {
  switch (section) {
    case 1:
      return interpolate(
        [events(isMobile).howItWorksStep1OpacityStart, events(isMobile).howItWorksStep1OpacityEnd],
        [0, 1]
      )
    case 2:
      return interpolate(
        [events(isMobile).howItWorksStep2OpacityStart, events(isMobile).howItWorksStep2OpacityEnd],
        [0, 1]
      )
    case 3:
      return interpolate(
        [events(isMobile).howItWorksStep3OpacityStart, events(isMobile).howItWorksStep3OpacityEnd],
        [0, 1]
      )
  }
}

interface IProps {
  scrollRatio: number
  sectionWidth: number
  windowWidth: number
}

/** Component that renders the How it works section for desktop. */
const HowItWorksDesktop = ({ scrollRatio, windowWidth, sectionWidth }: IProps) => {
  const {
    windowHeight,
    pageData: { sections, images },
    updateAnchor,
    isMobile,
  } = useContext(GlobalStateContext)
  const sphereRef: RefObject<HTMLImageElement> = useRef()
  const sphereOffsetFromTop = MenuHeights.desktop + GRID_TITLE_HEIGHT + GRID_ROW_GAP
  const sideAlignment = fixedSideAlignment(windowWidth, sectionWidth)
  const columnGap = 0.1 * sectionWidth
  const contentWidth = 0.8 * sectionWidth
  const columnWidth = (contentWidth - 2 * columnGap) / 3
  const sectionHeights = getSectionHeights(windowHeight, false)

  useEffect(() => {
    const sphereHeight = sphereRef.current?.offsetHeight || 0
    const sphereData = {
      top:
        (scrollRatio < events(isMobile).howItWorksSectionReachedTop
          ? -(scrollRatio * windowHeight) + sectionHeights[0] + sectionHeights[1]
          : 0) + sphereOffsetFromTop,
      left: sideAlignment + (columnWidth > sphereHeight ? (columnWidth - sphereHeight) / 2 : 0),
      width: sphereRef.current.offsetWidth,
      height: sphereHeight,
    }

    updateAnchor('howToSphere', sphereData)
  }, [sphereRef, scrollRatio])
  const sectionData = sections[2]
  const stepData = sections[2].data.sort((step1, step2) => step1.step - step2.step)

  const imageStyle = { height: `${0.3 * windowHeight}px`, maxHeight: `${columnWidth}px` }

  return (
    <FixedToAbsolute
      id="howItWorksSectionDesktop"
      className={styles.internalWrapper}
      ratio={scrollRatio}
      intervals={[
        {
          start: events(isMobile).start,
          end: events(isMobile).howItWorksSectionReachedTop,
          position: 'absolute',
          top: 0,
          left: 0,
        },
        {
          start: events(isMobile).howItWorksSectionReachedTop,
          end: events(isMobile).howItWorksSectionShouldScrollOut,
          position: 'fixed',
          top: 0,
          left: 0,
        },
        {
          start: events(isMobile).howItWorksSectionShouldScrollOut,
          position: 'absolute',
          top: sectionViewportOffset(2, events(isMobile).howItWorksSectionShouldScrollOut, windowHeight, false),
          left: 0,
        },
      ]}
      sectionWidth={sectionWidth}
    >
      <div
        className={styles.layout}
        css={{
          height: `${windowHeight}px`,
        }}
      >
        <div css={{ height: `${MenuHeights.desktop}px` }} />
        <div
          className={styles.contents}
          css={{
            width: `${contentWidth}px`,
            gridTemplateRows: `${GRID_TITLE_HEIGHT}px repeat(4, auto)`,
            gridColumnGap: `${columnGap}px`,
            gridRowGap: `${GRID_ROW_GAP}px`,
          }}
        >
          <div className={styles.headerText}>{sectionData.title}</div>
          <div className={styles.step1ImageContainer} css={{ height: `${columnWidth}px` }}>
            <ResponsiveImage
              className={styles.image}
              variants={images['sphere4']}
              alt="Signup sphere"
              css={{ ...imageStyle, opacity: sphere1Level1OpacityInterpolator(isMobile)(scrollRatio) }}
              imageRef={sphereRef}
            />
            <ResponsiveImage
              className={styles.image}
              variants={images['step1Final']}
              alt="Signup mobile"
              css={{ ...imageStyle, opacity: sphere1Level2OpacityInterpolator(isMobile)(scrollRatio) }}
            />
          </div>
          <div className={styles.step2ImageContainer} css={{ height: `${columnWidth}px` }}>
            <ResponsiveImage
              className={styles.image}
              variants={images['sphere5']}
              alt="QPad sphere"
              css={{
                ...imageStyle,
                opacity: sphere2Level1OpacityInterpolator(isMobile)(scrollRatio),
                transform: 'scale(1.1)',
              }}
            />
            <ResponsiveImage
              className={styles.image}
              variants={images['step2Final']}
              alt="QPad"
              css={{ ...imageStyle, opacity: sphere2Level2OpacityInterpolator(isMobile)(scrollRatio) }}
            />
          </div>
          <div className={styles.step3ImageContainer} css={{ height: `${columnWidth}px` }}>
            <ResponsiveImage
              className={styles.image}
              variants={images['step3Final']}
              alt="Metrics"
              css={{ ...imageStyle, opacity: sphere3Level2OpacityInterpolator(isMobile)(scrollRatio) }}
            />
          </div>
          {stepData.map((step, stepNumber) => (
            <div
              key={`stepData${step.step}`}
              css={{ opacity: sectionTextOpacityInterpolator(stepNumber + 1, isMobile)(scrollRatio) }}
              className={`${styles[`step${step.step}Description`]}`}
            >
              <div className={styles.number}>{`0${step.step}`}</div>
              <div className={styles.title}>{step.title}</div>
              <div className={styles.description}>{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </FixedToAbsolute>
  )
}

export default HowItWorksDesktop
