import { MenuHeights } from '../utils/useScrollPosition'
import { responsiveSections } from '../utils/useScrollPosition'
import { Sections } from '../types/types'

const navigationBarRatio = (isMobile: boolean, windowHeight: number) => {
  return isMobile ? MenuHeights.mobile / windowHeight : MenuHeights.desktop / windowHeight
}

const events = (isMobile: boolean) => ({
  start: 0,
  missionTitleFadeOutStart: responsiveSections(isMobile)[Sections.mission].startsAt + 0.2,
  missionTitleFadeOutEnd: responsiveSections(isMobile)[Sections.mission].startsAt + (isMobile ? 0.5 : 1),
  missionDescriptionFadeInStart: responsiveSections(isMobile)[Sections.mission].startsAt + (isMobile ? 0.3 : 0.8),
  missionDescriptionFadeInEnd: responsiveSections(isMobile)[Sections.mission].startsAt + (isMobile ? 1 : 1.5),
  missionDescriptionFadeOutEnd: responsiveSections(isMobile)[Sections.mission].startsAt + (isMobile ? 1.5 : 2),
  qPadTitleFadeInStart: responsiveSections(isMobile)[Sections.qpad].startsAt - 0.3,
  qPadTitleFadeInEnd: responsiveSections(isMobile)[Sections.qpad].startsAt,
  qPadSectionReachedTop: (windowHeight: number) =>
    responsiveSections(isMobile)[Sections.qpad].startsAt - navigationBarRatio(isMobile, windowHeight),
  qPadDescriptionFadeInStart: responsiveSections(isMobile)[Sections.qpad].startsAt,
  qPadDescriptionFadeInEnd: responsiveSections(isMobile)[Sections.qpad].startsAt + 0.1,
  qPadSectionShouldScrollOut: responsiveSections(isMobile)[Sections.qpad].startsAt + 0.3,
  howItWorksSectionReachedTop: responsiveSections(isMobile)[Sections.howTo].startsAt,

  //How it works desktop events
  howItWorksSphere1Level1OpacityOffStart: responsiveSections(false)[Sections.howTo].startsAt + 0.1,
  howItWorksSphere1Level1OpacityOffEnd: responsiveSections(false)[Sections.howTo].startsAt + 0.4,
  howItWorksSphere2Level1OpacityStart: responsiveSections(false)[Sections.howTo].startsAt - 0.2,
  howItWorksSphere2Level1OpacityEnd: responsiveSections(false)[Sections.howTo].startsAt + 0.1,
  howItWorksSphere2Level1OpacityOffStart: responsiveSections(false)[Sections.howTo].startsAt + 0.3,
  howItWorksSphere2Level1OpacityOffEnd: responsiveSections(false)[Sections.howTo].startsAt + 0.6,
  howItWorksSectionShouldScrollOut: responsiveSections(isMobile)[Sections.howTo].startsAt + (isMobile ? 2.2 : 1),
  howItWorksStep1OpacityStart: responsiveSections(false)[Sections.howTo].startsAt + 0.1,
  howItWorksStep1OpacityEnd: responsiveSections(false)[Sections.howTo].startsAt + 0.4,
  howItWorksStep2OpacityStart: responsiveSections(false)[Sections.howTo].startsAt + 0.3,
  howItWorksStep2OpacityEnd: responsiveSections(false)[Sections.howTo].startsAt + 0.6,
  howItWorksStep3OpacityStart: responsiveSections(false)[Sections.howTo].startsAt + 0.5,
  howItWorksStep3OpacityEnd: responsiveSections(false)[Sections.howTo].startsAt + 0.9,
  //How it works desktop events end

  //How it works mobile events start
  howItWorksStep1ReachedImage: responsiveSections(true)[Sections.howTo].startsAt + 0.2,
  howItWorksStep2ScrolledIn: responsiveSections(true)[Sections.howTo].startsAt + 0.7,
  howItWorksStep1ScrolledOut: responsiveSections(true)[Sections.howTo].startsAt + 1,
  howItWorksStep2ReachedImage: responsiveSections(true)[Sections.howTo].startsAt + 1.2,
  howItWorksStep2ScrolledOut: responsiveSections(true)[Sections.howTo].startsAt + 1.8,
  howItWorksStep3ScrolledIn: responsiveSections(true)[Sections.howTo].startsAt + 1.7,
  howItWorksStep3ReachedImage: responsiveSections(true)[Sections.howTo].startsAt + 2,

  // SPHERE EVENTS START
  sphereMissionToQPadMoveStarts: responsiveSections(isMobile)[Sections.mission].startsAt + 1.6,
  sphereMissionToQPadMoveEnds: responsiveSections(isMobile)[Sections.mission].startsAt + 2.2,
  sphereQPadToHowToMoveStarts: responsiveSections(isMobile)[Sections.qpad].startsAt + 0.3,
  sphereQPadToHowToMoveEnds: responsiveSections(isMobile)[Sections.qpad].startsAt + 0.9,
  sphereQPadToHowToHorizontalStarts: responsiveSections(isMobile)[Sections.howTo].startsAt - 0.4,
  sphereQPadToHowToHorizontalEnds: responsiveSections(isMobile)[Sections.howTo].startsAt,
  sphereLeavesHowToStep2: responsiveSections(isMobile)[Sections.howTo].startsAt - 0.1,
  sphereEndsHowToStep3: responsiveSections(isMobile)[Sections.howTo].startsAt,
  howItWorksGlobalSphereOpacityOffStart: responsiveSections(isMobile)[Sections.howTo].startsAt + 0.5,
  howItWorksGlobalSphereOpacityOffEnd: responsiveSections(isMobile)[Sections.howTo].startsAt + 0.9,
  // SPHERE EVENTS END

  // POWER TO THE PERIOD EVENTS
  powerSectionReachedTop: (windowHeight: number) =>
    responsiveSections(isMobile)[Sections.power].startsAt - navigationBarRatio(isMobile, windowHeight),
  powerVideoStarted: responsiveSections(isMobile)[Sections.power].startsAt + 0.1,
  powerVideoWhiteLineStart: responsiveSections(isMobile)[Sections.power].startsAt + 1,
  powerTitleOpacityOnStart: responsiveSections(isMobile)[Sections.power].startsAt + 1.3,
  powerTitleOpacityOnEnd: responsiveSections(isMobile)[Sections.power].startsAt + 1.5,
  powerDescriptionOpacityOnStart: responsiveSections(isMobile)[Sections.power].startsAt + 1.5,
  powerDescriptionOpacityOnEnd: responsiveSections(isMobile)[Sections.power].startsAt + 2,
  powerVideoFinished: responsiveSections(isMobile)[Sections.power].startsAt + 2.8,
  powerSectionShouldScrollOut: responsiveSections(isMobile)[Sections.power].startsAt + 3,

  // ABOUT US EVENTS
  aboutUsSectionReachedTop: responsiveSections(isMobile)[Sections.aboutUs].startsAt,
  videoSectionReachedTop: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.6 : 0),
  aboutUsSectionTitleOpacityStart: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0 : 0.06),
  aboutUsSectionTitleOpacityEnd: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.05 : 0.1),
  aboutUsSectionImageOpacityStart: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.05 : 0.1),
  aboutUsSectionImageOpacityEnd: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.1 : 0.15),
  aboutUsSectionDescriptionOpacityStart:
    responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.1 : 0.15),
  aboutUsSectionDescriptionOpacityEnd:
    responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.15 : 0.2),
  aboutUsSectionLearnMoreOpacityStart:
    responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.15 : 0.2),
  aboutUsSectionLearnMoreOpacityEnd: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 0.2 : 0.25),
  aboutUsSectionWhoWeAreTransparencyStart: responsiveSections(isMobile)[Sections.aboutUs].startsAt + 0.25,
  aboutUsSectionWhoWeAreTransparencyEnd: responsiveSections(isMobile)[Sections.aboutUs].startsAt + 0.3,
  aboutUsSectionWhoWeAreScrolledOutFounders:
    responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 1.05 : 0.95),
  aboutUsVideoOpacityStart: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 2.35 : 1.95),
  aboutUsVideoFinished: responsiveSections(isMobile)[Sections.aboutUs].startsAt + 3.2,
  aboutUsSectionVideoScaleStart: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 1.4 : 3),
  aboutUsSectionVideoScaleEnd: responsiveSections(isMobile)[Sections.aboutUs].startsAt + (isMobile ? 2.8 : 3.2),

  end:
    responsiveSections(isMobile)[Sections.contact].startsAt + responsiveSections(isMobile)[Sections.contact].height - 1,
})

const mobileEvents = events(true)
const desktopEvents = events(false)

export default (isMobile: boolean) => (isMobile ? mobileEvents : desktopEvents)
