import React, { useContext, useRef, useEffect, RefObject, LegacyRef } from 'react'
import useSectionWidth from '../../utils/useSectionWidth'
import useResponsive from '../../utils/useResponsive'
import { getSectionHeights, MenuHeights } from '../../utils/useScrollPosition'
import ResponsiveImage from '../../components/responsiveImage'
import ScrollableSection from '../../components/scrollableSection'
import GlobalStateContext from '../../state'
import styles from './StayInTouch.module.css'

/** Renders the Stay in touch section. */
const StayInTouchSection = () => {
  const { sectionRef, sectionWidth, windowWidth } = useSectionWidth()
  const {
    windowHeight,
    pageData: { images, stayInTouchSection },
  } = useContext(GlobalStateContext)
  const { isMobile: isMobileSection } = useResponsive()

  return (
    <div
      className={styles.section}
      ref={sectionRef}
      css={{ height: `${getSectionHeights(windowHeight, isMobileSection)[5]}px` }}
    >
      <div className={styles.contents}>
        <div className={styles.newsletterContainer}>
          <div className={styles.title}>{stayInTouchSection.signUpTitle}</div>
          <div className={styles.description}>{stayInTouchSection.signUpDescription}</div>
          <div className={styles.formContainer}>
            <form
              action="https://qurasense.us11.list-manage.com/subscribe/post?u=77640e41f3039ffb445091db0&amp;id=429b40ae5d"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
              noValidate
            >
              <input
                type="email"
                className={styles.emailInput}
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="Email"
                required
              />
              <button type="submit" className={styles.submitButton}>
                <ResponsiveImage variants={images['arrow']} alt="Send arrow" className={styles.buttonIcon} />
              </button>
            </form>
          </div>
        </div>
        <div className={styles.downloadContainer}>
          <div className={styles.title}>{stayInTouchSection.downloadTitle}</div>
          <div className={styles.description}>{stayInTouchSection.downloadDescription}</div>
          <div className={styles.storeContainer}>
            <a href={stayInTouchSection.iosUrl} target="_blank" rel="noopener noreferrer">
              <ResponsiveImage variants={images['appStore']} alt="App Store" className={styles.storeImage} />
            </a>
            <a href={stayInTouchSection.googleUrl} target="_blank" rel="noopener noreferrer">
              <ResponsiveImage variants={images['googlePlay']} alt="Google Play" className={styles.storeImage} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StayInTouchSection
