import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import useScrollPosition from '../../utils/useScrollPosition'

type Props = {
  windowHeight: number
}

function EmptyNavBar({ windowHeight }: Props) {
  const data = useStaticQuery(graphql`
    query {
      logo: contentfulAsset(title: { eq: "qvin_logo" }) {
        fixed(height: 40) {
          ...GatsbyContentfulFixed
        }
      }
    }
  `)

  const scrollRatio = useScrollPosition(windowHeight)

  return (
    <nav
      className={cx('w-full max-h-20 py-4 fixed z-50', {
        ['bg-transparent']: scrollRatio < 0.1,
        ['bg-white']: scrollRatio > 0.1,
      })}
    >
      <div className="container mx-auto px-8 h-full">
        <Link to="/">
          <Img fixed={data.logo.fixed} className="md:h-12 h-8" />
        </Link>
      </div>
    </nav>
  )
}
export default EmptyNavBar