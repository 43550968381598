import React, { useContext } from 'react'
import GlobalStateContext from '../../state'
import { MenuHeights } from '../../utils/useScrollPosition'
import ResponsiveImage from '../../components/responsiveImage'
import { FixedToAbsolute } from '../../components/positioning'
import events from '../storyline'
import styles from './HowItWorksMobile.module.css'
import { interpolate } from '@popmotion/popcorn'

interface IProps {
  scrollRatio: number
  sectionWidth: number
}

const step1ImageOpacityInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).howItWorksStep1ReachedImage, events(isMobile).howItWorksStep1ScrolledOut], [1, 0])

const step2ImageOpacityInterpolator = (isMobile: boolean) =>
  interpolate(
    [
      events(isMobile).howItWorksStep2ScrolledIn,
      events(isMobile).howItWorksStep2ReachedImage,
      events(isMobile).howItWorksStep2ScrolledOut,
    ],
    [0, 1, 0]
  )

const step3ImageOpacityInterpolator = (isMobile: boolean) =>
  interpolate([events(isMobile).howItWorksStep3ScrolledIn, events(isMobile).howItWorksStep3ReachedImage], [0, 1])

const TITLE_HEIGHT = 32

/** Component that renders the How it works section for desktop. */
const HowItWorksMobile = ({ scrollRatio, sectionWidth }: IProps) => {
  const {
    windowHeight,
    pageData: { sections, images },
    updateAnchor,
    isMobile,
  } = useContext(GlobalStateContext)
  const sectionData = sections[2]
  const stepData = sections[2].data.sort((step1, step2) => step1.step - step2.step)

  const imageStyle = { maxHeight: `${sectionWidth * 0.8}px`, left: '50%', transform: 'translateX(-50%)' }
  const imageTop = TITLE_HEIGHT + MenuHeights.mobile
  return (
    <>
      <div className={styles.layout} css={{ height: `${windowHeight}px` }}>
        <div css={{ height: `${MenuHeights.mobile}px` }} />
        <div className={styles.headerText} css={{ height: `${TITLE_HEIGHT}px` }}>
          {sectionData.title}
        </div>
        <FixedToAbsolute
          id="howItWorksSectionMobileImages"
          className={styles.internalWrapper}
          ratio={scrollRatio}
          intervals={[
            {
              start: events(isMobile).start,
              end: events(isMobile).howItWorksSectionReachedTop,
              position: 'absolute',
              top: MenuHeights.mobile + TITLE_HEIGHT,
              left: 0,
            },
            {
              start: events(isMobile).howItWorksSectionReachedTop,
              end: events(isMobile).howItWorksStep3ReachedImage,
              position: 'fixed',
              top: imageTop,
              left: 0,
            },
            {
              start: events(isMobile).howItWorksStep3ReachedImage,
              position: 'absolute',
              top:
                (events(isMobile).howItWorksStep3ReachedImage - events(isMobile).howItWorksSectionReachedTop) *
                  windowHeight +
                imageTop,
              left: 0,
            },
          ]}
          sectionWidth={sectionWidth}
        >
          <ResponsiveImage
            className={styles.image}
            variants={images['step1Final']}
            alt="Signup mobile"
            css={{ ...imageStyle, opacity: step1ImageOpacityInterpolator(isMobile)(scrollRatio) }}
          />
          <ResponsiveImage
            className={styles.image}
            variants={images['step2Final']}
            alt="QPad mobile"
            css={{ ...imageStyle, opacity: step2ImageOpacityInterpolator(isMobile)(scrollRatio) }}
          />
          <ResponsiveImage
            className={styles.image}
            variants={images['step3Final']}
            alt="Analytics mobile"
            css={{ ...imageStyle, opacity: step3ImageOpacityInterpolator(isMobile)(scrollRatio) }}
          />
        </FixedToAbsolute>
        {stepData.map((step, stepNumber) => (
          <div
            key={`stepData${step.step}`}
            className={styles.stepCardContainer}
            css={{
              top: `${stepNumber * windowHeight + 0.6 * windowHeight + 32}px`,
              minHeight: `${windowHeight * 0.4 - 32}px`,
            }}
          >
            <div className={styles.stepCard}>
              <div className={styles.number}>{`0${step.step}`}</div>
              <div className={styles.title}>{step.title}</div>
              <div className={styles.description}>{step.description}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default HowItWorksMobile
